<template>
  <div>
    <div>
      <el-image fit="contain" :src="abouts" style="width:100%;" />
    </div>
    <el-breadcrumb separator="/" style="margin-left:10px; margin-top:10px">
      <el-breadcrumb-item :to="{ path: '/' }">{{
        $t('Nav.home')
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('Nav.lianxiwomen') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin-top:20px; position: relative;">
      <el-image fit="contain" :src="ict" style="width:100%;" />
    </div>
    <div
      style="color:#4a58ff; font-weight:bold;margin-top:20px;margin-bottom:10px;font-size:20px"
    >
      {{ $t('Nav.lianxiwomen') }}
    </div>
    <div>{{ $t('Nav.danwei') }}</div>
    <div>
      {{ $t('Nav.dizhi') }}<br />
      {{ $t('Nav.cairun') }}<br />
      {{ $t('Nav.dizhi2') }} {{ $t('Nav.dizhi2_1') }}<br />
      {{ $t('Nav.dizhi3') }}
      <!-- <br />
      {{ $t('Nav.dizhi2') }}<br />
      {{ $t('Nav.dizhi3') }} -->
    </div>
    <div>{{ $t('Nav.dianhua') }}</div>
    <div>{{ $t('Nav.youjian') }}：gl_hresource@126.com</div>
    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'itc',
  components: {
    // HelloWorld
  },
  data() {
    return {
      abouts: require('../../assets/index/05.jpg'),
      ict: require('../../assets/mobile/07-img@3x.png'),
      point: require('../../assets/point.gif'),
    }
  },
  methods: {},
}
</script>

<style></style>
